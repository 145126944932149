<template>
  <main>
    <trac-loading v-if="isLoading"></trac-loading>
    <div v-else>
      <h1 class="text-2xl font-bold">KYC Override Portal</h1>
      <div class="bg-blue-100 p-5">
        <p>
          Please note that this page only shows businesses whose KYC is false.
        </p>
      </div>
      <!-- <div class="flex justify-end items-end my-5">
        <button
          @click="downloadCSV"
          class="
                    block
                    uppercase
                    shadow
                    ml-2
                    text-sm
                    bg-blue-900
                    text-white
                    hover:bg-blue-600
                    focus:shadow-outline
                    focus:outline-none
                    py-3
                    px-8
                    rounded
                    
                  "
        >
          Download CSV
        </button>
      </div> -->
      <div class="flex items-center my-5 mt-10">
        <input
          type="text"
          class="inline-block px-3 ring-2 outline-none ring-blue-500 rounded-sm w-80 text-sm py-2"
          name=""
          placeholder="Search by Business Name"
          id=""
          v-model="search"
        />
        <button
          @click="handleSearch"
          class="block uppercase shadow ml-2 text-sm bg-blue-900 text-white hover:bg-blue-700 focus:shadow-outline focus:outline-none py-3 px-8 rounded"
        >
          Search
        </button>
        <button
          @click="resetSearch"
          class="block uppercase shadow ml-2 text-sm bg-red-900 text-white hover:bg-red-700 focus:shadow-outline focus:outline-none py-3 px-8 rounded"
        >
          Reset
        </button>
      </div>
      <div class="flex bg-blue-50 p-5">
        <div class="flex-2 inline-block time-filter">
          <p>From:</p>
          <input
            type="date"
            class="inline-block border-2 px-2 rounded-sm mr-2"
            name=""
            id=""
            v-model="dateData.startdate"
          />
        </div>

        <div class="flex-2 inline-block time-filter">
          <p>To:</p>
          <input
            type="date"
            class="inline-block border-2 px-2 rounded-sm mr-2"
            name=""
            id=""
            v-model="dateData.enddate"
          />
        </div>
        <div class="flex mt-5">
          <button
            @click="fetchDatedFilteredRecords"
            class="block uppercase shadow bg-green-800 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white text-xs py-2 px-3 rounded"
          >
            Filter
          </button>
          <button
            @click="clearDateFilter"
            class="block uppercase shadow bg-red-800 hover:bg-red-700 ml-2 focus:shadow-outline focus:outline-none text-white text-xs py-2 px-3 rounded"
          >
            Reset
          </button>
        </div>
      </div>
      <div class="my-5 flex justify-end items-end">
        <button
          @click="fetchAll"
          class="block uppercase shadow ml-3 text-xs hover:bg-blue-700 focus:shadow-outline focus:outline-none hover:text-white py-3 px-8 rounded"
          :class="
            notKYCFlag === false && approvedKYCFlag === false
              ? 'bg-blue-700 text-white'
              : 'bg-white text-blue-700'
          "
        >
          All
        </button>
        <button
          @click="notKYC"
          class="block uppercase shadow ml-3 hover:bg-blue-700 hover:text-white focus:shadow-outline focus:outline-none text-black text-xs py-3 px-8 rounded"
          :class="
            notKYCFlag === true
              ? 'bg-blue-700 text-white'
              : 'bg-white text-blue-700'
          "
        >
          Not Overidden
        </button>
        <button
          @click="approvedKYC"
          class="block uppercase shadow ml-3 hover:bg-blue-700 focus:shadow-outline hover:text-white focus:outline-none text-black text-xs py-3 px-8 rounded"
          :class="
            approvedKYCFlag === true
              ? 'bg-blue-700 text-white'
              : 'bg-white text-blue-700'
          "
        >
          Overidden
        </button>
      </div>
      <button
        class="bg-gray-300 text-black text-xs py-3 px-6 rounded-md mr-3 text-black mb-3 font-semibold"
        @click="overrideKYCSelected"
      >
        Doc Override Selected ({{ selected.length }})
      </button>
      <button
        class="bg-black text-xs py-3 px-6 rounded-md text-white mb-3 font-semibold"
        @click="unOverrideKYCSelected"
      >
        Doc Un-Override Selected ({{ selected.length }})
      </button>
      <button
        class="bg-yellow-300 ml-2 text-black text-xs py-3 px-6 rounded-md mr-3 text-black mb-3 font-semibold"
        @click="overrideBVNSelected"
      >
        BVN Override Selected ({{ selected.length }})
      </button>
      <button
        class="bg-black text-xs py-3 px-6 rounded-md text-white mb-3 font-semibold"
        @click="unOverrideBVNSelected"
      >
        BVN Un-Override Selected ({{ selected.length }})
      </button>
      <div class="mt-5" style="height: 600px; overflow: auto">
        <table class="shadow-lg bg-white w-full relative border-collapse">
          <tr>
            <th
              class="text-l p-3 text-left"
              colspan="14"
              style="background: #dee6f2"
            >
              Merchant Information
            </th>
          </tr>
          <tr>
            <th
              class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
            >
              <input type="checkbox" v-model="selectAll" />
            </th>
            <th
              class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
            >
              Business Name
            </th>
            <th
              class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
            >
              Merchant name
            </th>
            <th
              class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
            >
              Phone Number
            </th>
            <th
              class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
            >
              Email Address
            </th>

            <th
              class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
            >
              BVN Override Status
            </th>
            <th
              class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
            >
              ID Override Status
            </th>

            <th
              class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
            >
              Action
            </th>
          </tr>
          <tr v-for="(account, i) in filterAccounts" :key="i">
            <td>
              <input type="checkbox" :value="account._id" v-model="selected" />
            </td>
            <td class="border px-8 py-3 whitespace-nowrap text-xs">
              {{ account.businessName || "N/A" | truncate(30, "...") }}
            </td>
            <td class="border px-8 py-3 whitespace-nowrap text-xs">
              {{ account.merchantName }}
            </td>
            <td class="border px-8 py-3 whitespace-nowrap text-xs">
              {{ account.phoneNumber }}
            </td>
            <td class="border px-8 py-3 whitespace-nowrap text-xs">
              {{ account.emailAddress }}
            </td>
            <td
              class="border px-8 py-3 whitespace-nowrap text-xs"
              :class="account.bvnExempted ? 'text-green-500' : 'text-red-500'"
            >
              {{ account.bvnExempted ? "Overidden" : "Not Overidden" }}
            </td>
            <td
              class="border px-8 py-3 whitespace-nowrap text-xs"
              :class="
                account.documentExempted ? 'text-green-500' : 'text-red-500'
              "
            >
              {{ account.documentExempted ? "Approved" : "Not Approved" }}
            </td>
            <td class="border px-8 py-3 whitespace-nowrap text-xs">
              <button
                v-if="!account.hasKycOverride"
                @click="overrideKYC(account._id)"
                class="block uppercase shadow ml-3 hover:bg-white bg-red-200 focus:shadow-outline focus:outline-none text-black text-xs font-bold py-3 px-8 rounded"
              >
                Override Doc
              </button>
              <button
                @click="unoverrideKYC(account._id)"
                v-else
                class="block uppercase shadow ml-3 bg-red-400 hover:bg-blue-700 hover:text-white focus:shadow-outline focus:outline-none text-black font-bold text-xs py-3 px-8 rounded"
              >
                Un-Override Doc
              </button>
              <button
                v-if="!account.hasKycOverride"
                @click="overrideBVN(account._id)"
                class="block mt-3 uppercase shadow ml-3 hover:bg-white bg-blue-200 focus:shadow-outline focus:outline-none text-black text-xs font-bold py-3 px-8 rounded"
              >
                Override BVN
              </button>
              <button
                @click="unoverrideBVN(account._id)"
                v-else
                class="block mt-3 uppercase shadow ml-3 bg-blue-700 hover:bg-blue-200 hover:text-black focus:shadow-outline focus:outline-none text-white text-xs py-3 px-8 rounded"
              >
                Un-Override BVN
              </button>
            </td>
          </tr>
        </table>
      </div>
      <div class="mt-3">
        <button
          @click="prevPage(currentPage)"
          :disabled="currentPage === 1 ? isDisabled : !isDisabled"
          class="uppercase shadow ml-3 hover:bg-blue-600 focus:shadow-outline focus:outline-none text-xs font-bold py-3 px-8 rounded"
          :class="
            currentPage === 1
              ? 'bg-blue-50 text-black'
              : 'bg-blue-900 text-white'
          "
        >
          &laquo; Prev
        </button>

        <button
          @click="nextPage(currentPage)"
          class="float-right uppercase shadow ml-3 hover:bg-blue-600 bg-blue-900 focus:shadow-outline focus:outline-none text-white text-xs font-bold py-3 px-8 rounded"
          v-if="
            this.GET_OVERRIDE.data.items != null &&
            this.GET_OVERRIDE.data.items.length > 99
          "
        >
          Next &raquo;
        </button>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  data() {
    return {
      moment,
      isLoading: false,
      search: "",
      accounts: null,
      currentPage: 1,
      totalPages: 0,
      statusSort: "all",
      approvedKYCFlag: false,
      notKYCFlag: false,
      numberOfItems: 0,
      selected: [],
      selectedWhitelist: [],
      isDisabled: true,
      overrideStatus: "un-override",
      dateFilter: false,
      searchFilter: false,
      filterStatus: false,
      dateData: null,
    };
  },
  computed: {
    ...mapGetters(["GET_OVERRIDE"]),
    filterAccounts() {
      return this.GET_OVERRIDE.data.items;
      // if (
      //   this.GET_OVERRIDE.data.items !== null &&
      //   this.GET_OVERRIDE.data.items.length > 0
      // ) {
      //   return this.GET_OVERRIDE.data.items.filter(
      //     (account) =>
      //       account.businessName
      //         .toLowerCase()
      //         .includes(this.search.toLowerCase().trim()) ||
      //       account.merchantName
      //         .toLowerCase()
      //         .includes(this.search.toLowerCase()) ||
      //       account.emailAddress
      //         .toLowerCase()
      //         .includes(this.search.toLowerCase().trim()) ||
      //       account.phoneNumber
      //         .toLowerCase()
      //         .includes(this.search.toLowerCase().trim())
      //   );
      // } else {
      //   return [];
      // }
    },
    all() {
      return this.filterAccounts;
    },
    selectAll: {
      get: function () {
        return this.filterAccounts
          ? this.selected.length == this.filterAccounts.length
          : false;
      },
      set: function (value) {
        var selected = [];

        if (value) {
          if (this.filterAccounts !== null) {
            this.filterAccounts.forEach(function (account) {
              selected.push(account._id.toString());
            });
          }
        }

        this.selected = selected;
      },
    },
  },
  filters: {
    truncate: function (text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },
  async mounted() {
    // this.isLoading = true;
  },
  async created() {
    const d = new Date();
    this.dateData = {
      startdate: new Date(d.setDate(d.getDate() - 7)).toJSON().split("T")[0],
      enddate: new Date().toJSON().split("T")[0],
      startTime: "00:00",
      endTime: this.moment(d).format("HH:mm"),
    };

    this.fetchAllRecords();
  },
  methods: {
    // ...mapGetters(["GET_OVERRIDE"]),
    async fetchAllRecords() {
      this.isLoading = true;
      let payload = {
        page: this.currentPage,
        date: this.dateData,
        status: this.overrideStatus,
        search: this.search,
        dateFilter: this.dateFilter,
        searchFilter: this.searchFilter,
        filterStatus: this.filterStatus,
      };
      let response = await this.$store.dispatch(
        "FETCH_OVERRIDDEN_KYC_PORTAL",
        payload
      );
      this.accounts = response;
      if (response.data) {
        this.isLoading = false;
      }
      this.isLoading = false;
    },
    async markAsFraudulent(id, status) {
      let res = confirm("Are you sure you want to perform this operation?");
      this.isLoading = true;
      let payload = [id, status];
      if (res) {
        let res = await this.$store.dispatch("UPDATE_FRAUD_STATUS", payload);
        if (res.status) {
          alert("Status was updated successfully");
          setTimeout(() => {
            this.$router.go();
          }, 200);
        } else {
          alert(res.error);
        }
      }
      this.isLoading = false;
    },
    async prevPage() {
      this.isLoading = true;
      let payload = {
        page: this.currentPage - 1,
        date: this.dateData,
        status: this.overrideStatus,
        search: this.search,
        dateFilter: this.dateFilter,
        searchFilter: this.searchFilter,
        filterStatus: this.filterStatus,
      };
      let response = await this.$store.dispatch(
        "FETCH_OVERRIDDEN_KYC_PORTAL",
        payload
      );
      this.accounts = response;
      if (response.data) {
        this.isLoading = false;
      }
      this.isLoading = false;
    },
    async nextPage() {
      this.isLoading = true;
      let payload = {
        page: this.currentPage + 1,
        date: this.dateData,
        status: this.overrideStatus,
        search: this.search,
        dateFilter: this.dateFilter,
        searchFilter: this.searchFilter,
        filterStatus: this.filterStatus,
      };
      let response = await this.$store.dispatch(
        "FETCH_OVERRIDDEN_KYC_PORTAL",
        payload
      );
      this.accounts = response;
      if (response.data) {
        this.isLoading = false;
      }
      this.isLoading = false;
    },
    async fetchDatedFilteredRecords() {
      this.isLoading = true;
      this.dateFilter = true;
      let payload = {
        page: this.currentPage,
        date: this.dateData,
        status: this.overrideStatus,
        search: this.search,
        dateFilter: this.dateFilter,
        searchFilter: this.searchFilter,
        filterStatus: this.filterStatus,
      };
      let response = await this.$store.dispatch(
        "FETCH_OVERRIDDEN_KYC_PORTAL",
        payload
      );
      this.accounts = response;
      if (response.data) {
        this.isLoading = false;
      }
      this.isLoading = false;
    },
    async handleSearch() {
      this.isLoading = true;
      this.searchFilter = true;
      let payload = {
        page: this.currentPage,
        date: this.dateData,
        status: this.overrideStatus,
        search: this.search,
        dateFilter: this.dateFilter,
        searchFilter: this.searchFilter,
        filterStatus: this.filterStatus,
      };
      let response = await this.$store.dispatch(
        "FETCH_OVERRIDDEN_KYC_PORTAL",
        payload
      );
      this.accounts = response;
      if (response.data) {
        this.isLoading = false;
      }
      this.isLoading = false;
    },

    async resetSearch() {
      this.fetchAllRecords();
      this.searchFilter = false;
      this.search = "";
    },

    async clearDateFilter() {
      this.fetchAllRecords();
      this.searchFilter = false;
      const d = new Date();
      this.dateData = {
        startdatetime: new Date(d.setDate(d.getDate() - 7))
          .toJSON()
          .split("T")[0],
        enddatetime: new Date().toJSON().split("T")[0],
        startTime: "00:00",
        endTime: this.moment(d).format("HH:mm"),
      };
    },

    async downloadCSV() {
      this.isLoading = true;

      let res = await this.$store.dispatch("DOWNLOAD_FRAUD_CSV");

      if (res.status) {
        window.open(res.data, "_blank");
      } else {
        alert("Could not find " + this.search);
        // console.log(res.data);
      }
      this.isLoading = false;
    },
    async approvedKYC() {
      this.isLoading = true;

      this.approvedKYCFlag = true;
      this.notKYCFlag = false;

      this.currentPage = 1;
      this.overrideStatus = "override";
      this.filterStatus = true;

      let payload = {
        page: this.currentPage,
        date: this.dateData,
        status: this.overrideStatus,
        search: this.search,
        dateFilter: this.dateFilter,
        searchFilter: this.searchFilter,
        filterStatus: this.filterStatus,
      };

      let res = await this.$store.dispatch(
        "FETCH_OVERRIDDEN_KYC_PORTAL",
        payload
      );

      if (res.status) {
      } else {
        alert("Could not find any record");
        // console.log(res.data);
      }
      this.isLoading = false;
    },
    async notKYC() {
      this.isLoading = true;
      this.notKYCFlag = true;
      this.approvedKYCFlag = false;

      this.currentPage = 1;
      this.overrideStatus = "un-override";
      this.filterStatus = true;

      let payload = {
        page: this.currentPage,
        date: this.dateData,
        status: this.overrideStatus,
        search: this.search,
        dateFilter: this.dateFilter,
        searchFilter: this.searchFilter,
        filterStatus: this.filterStatus,
      };

      let res = await this.$store.dispatch(
        "FETCH_OVERRIDDEN_KYC_PORTAL",
        payload
      );

      if (res.status) {
      } else {
        alert("Could not find any record");
        // console.log(res.data);
      }
      this.isLoading = false;
    },

    async fetchAll() {
      this.isLoading = true;
      this.notKYCFlag = false;
      this.approvedKYCFlag = false;

      this.currentPage = 1;
      this.overrideStatus = "";
      this.filterStatus = true;

      let payload = {
        page: this.currentPage,
        date: this.dateData,
        status: this.overrideStatus,
        search: this.search,
        dateFilter: this.dateFilter,
        searchFilter: this.searchFilter,
        filterStatus: this.filterStatus,
      };

      let res = await this.$store.dispatch(
        "FETCH_OVERRIDDEN_KYC_PORTAL",
        payload
      );

      if (res.status) {
      } else {
        alert("Could not find any record");
        // console.log(res.data);
      }
      this.isLoading = false;
    },

    async overrideKYC(id) {
      let res = confirm("Are you sure you want to override this account?");
      if (res === true) {
        this.isLoading = true;
        let payload = {
          businesses: [id],
        };
        try {
          let res = await this.$store.dispatch("OVERRIDE_KYC", payload);
          if (res.status == true) {
            alert("Override was successful");
            // this.$router.go();
            this.fetchAllRecords();
          } else {
            alert(
              res.error ||
                res.message ||
                "Error: Please check network or contact admin."
            );
          }
        } catch (error) {}
      }
    },
    async unoverrideKYC(id) {
      let res = confirm("Are you sure you want to un-override this account?");
      if (res === true) {
        this.isLoading = true;
        let payload = {
          businesses: [id],
        };
        try {
          let res = await this.$store.dispatch("UNOVERRIDE_KYC", payload);
          if (res.status == true) {
            alert("Un-override was successful");
            this.$router.go();
          } else {
            alert(
              res.error ||
                res.message ||
                "Error: Please check network or contact admin."
            );
          }
        } catch (error) {}
      }
    },
    async overrideBVN(id) {
      let res = confirm("Are you sure you want to override this account?");
      if (res === true) {
        this.isLoading = true;
        let payload = {
          businesses: [id],
        };
        try {
          let res = await this.$store.dispatch("OVERRIDE_BVN", payload);
          if (res.status == true) {
            alert("Override was successful");
            this.fetchAll();
          } else {
            alert(
              res.error ||
                res.message ||
                "Error: Please check network or contact admin."
            );
          }
        } catch (error) {}
      }
    },
    async unoverrideBVN(id) {
      let res = confirm("Are you sure you want to un-override this account?");
      if (res === true) {
        this.isLoading = true;
        let payload = {
          businesses: [id],
        };
        try {
          let res = await this.$store.dispatch("UNOVERRIDE_BVN", payload);
          if (res.status) {
            alert("Un-override was successful");
            this.fetchAll();
          } else {
            alert(
              res.error ||
                res.message ||
                "Error: Please check network or contact admin."
            );
          }
        } catch (error) {}
      }
    },
    async overrideKYCSelected() {
      let res = confirm("Are you sure you want to override these accounts?");
      if (res === true) {
        this.isLoading = true;
        this.selected.map((el) => {
          this.selectedWhitelist.push(el);
        });
        let payload = {
          businesses: this.selectedWhitelist,
        };
        try {
          let res = await this.$store.dispatch("OVERRIDE_KYC", payload);
          if (res.status == true) {
            alert("Override was successful");
            this.fetchAllRecords();
          } else {
            alert(
              res.error ||
                res.message ||
                "Error: Please check network or contact admin."
            );
          }
        } catch (error) {}
      }
    },
    async unOverrideKYCSelected() {
      let res = confirm("Are you sure you want to un-override these accounts?");
      if (res === true) {
        this.isLoading = true;
        this.selected.map((el) => {
          this.selectedWhitelist.push(el);
        });
        let payload = {
          businesses: this.selectedWhitelist,
        };
        try {
          let res = await this.$store.dispatch("UNOVERRIDE_KYC", payload);
          if (res.status == true) {
            alert("Un-override was successful");
            this.fetchAllRecords();
          } else {
            alert(
              res.error ||
                res.message ||
                "Error: Please check network or contact admin."
            );
          }
        } catch (error) {}
      }
    },
    async overrideBVNSelected() {
      let res = confirm("Are you sure you want to override these accounts?");
      if (res === true) {
        this.isLoading = true;
        this.selected.map((el) => {
          this.selectedWhitelist.push(el);
        });
        let payload = {
          businesses: this.selectedWhitelist,
        };
        try {
          let res = await this.$store.dispatch("OVERRIDE_BVN", payload);
          if (res.status == true) {
            alert("Override was successful");
            this.fetchAllRecords();
          } else {
            alert(
              res.error ||
                res.message ||
                "Error: Please check network or contact admin."
            );
          }
        } catch (error) {}
      }
    },
    async unOverrideBVNSelected() {
      let res = confirm("Are you sure you want to un-override these accounts?");
      if (res === true) {
        this.isLoading = true;
        this.selected.map((el) => {
          this.selectedWhitelist.push(el);
        });
        let payload = {
          businesses: this.selectedWhitelist,
        };
        try {
          let res = await this.$store.dispatch("UNOVERRIDE_BVN", payload);
          if (res.status == true) {
            alert("Un-override was successful");
            this.fetchAllRecords();
          } else {
            alert(
              res.error ||
                res.message ||
                "Error: Please check network or contact admin."
            );
          }
        } catch (error) {}
      }
    },
  },
};
</script>

<style scoped>
table {
  border-collapse: separate;
  border-spacing: 0;
  min-width: 350px;
}
table tr th:last-child {
  border-right: 1px solid rgb(241, 241, 241);
}
table tr th,
table tr td {
  /* border-right: 1px solid #bbb; */
  border-bottom: 1px solid rgb(238, 238, 238);
  padding: 18px;
}
table tr th:first-child,
table tr td:first-child {
  /* border-right: 1px solid rgb(223, 223, 223); */
  border-left: 1px solid rgb(241, 241, 241);
}
table tr th {
  background: rgb(255, 255, 255);
  border-top: 1px solid rgb(241, 241, 241);
  text-align: left;
}
table tr td {
  background: #f0f6fd;
}
/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
</style>
